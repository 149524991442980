//$black:                     #000;
$white:                     #fff;
//$green:                     #d3d62e;
$blue:                      #005e92;
$darkBlue:                      #003d5f;
$orange: #e4a300;

//$yellow:                    #fee93c;
//$red:                       #ed0c0c;
//$redDark:                   #cc0000;

//$grey:                      #666;
$greyLight:                 #eee;
$greyDark:                  #333;
//$greyMedium:                #777;

$fa-font: 'FontAwesome';
//$panel-border-radius: 0 0 15px 15px;
$panel-border-radius: 15px;
$panel-header-border-radius: 15px 15px 0 0;

@mixin shadow {
    -webkit-box-shadow: 0 0 10px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0 0 10px 0px rgba(0,0,0,0.1);
    box-shadow: 0 0 10px 0px rgba(0,0,0,0.1);
}

