@import "variables";

body {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: $blue;
    padding-top: 60px;
}

.btn {
    padding: 7px 12px;
    font-size: 13px;
    line-height: 13px;
    border-radius: 20px;
    box-shadow: none;
}

a {
    color: $darkBlue;
}

.btn-link {
    color: $blue;
}

.navbar {
    min-height: 60px;
    @include shadow;
}

.input-lg {
    font-size: 14px;
}

.help-block.help {
    color: #656565 !important;
    font-style: italic;
}

.toggle-success .toggle-slide .toggle-on,
.toggle-success .toggle-slide .toggle-on.active {
    background-color: $blue;
}

.toggle-success .toggle-slide.active .toggle-blob {
    border-color: $blue;
}

.toolbar.navbar-nav {
    margin-top: 10px;
}

.breadcrumb > .active {
    color: $blue;
}

header.navbar a.navbar-brand {
    margin: 0;
    height: 60px;
    width: auto;
    padding: 9px 15px 7px 15px;
    img {
        height: 40px;
    }
}

header #headerbardropdown,
header #rightmenu-trigger,
header .toolbar .dropdown {
    border: 0;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6, .btn {
    font-family: 'achilleiifyBold', Arial, Verdana, sans-serif;
}

h3 {
    border-bottom: 1px solid $orange;
    display: inline-block;
    color: $orange;
    margin-bottom: 25px;
}

.navbar-inverse {
    background-color: $white;
    .navbar-nav > li > a {
        color: $blue !important;
        background: transparent !important;
    }
    .navbar-nav > li > a:hover {
        color: $blue !important;
        background: transparent !important;
    }
}

#leftmenu-trigger {
    background-image: none;
    line-height: 60px;
    width: 60px;
    height: 60px;
    text-align: center;
    color: $greyDark;
    border: 0 !important;
    &:before {
        content: "\f0c9";
        font-family: $fa-font;
        font-size: 22px;
    }
    &:hover {
        text-decoration: none;
    }
}

#wrap {
    padding: 10px;
    > .container {
        padding: 0 20px 20px 20px;
    }
}

.row-action {
    .btn-default {
        background-color: $orange;
        i {
            font-size: 16px;
        }
    }
    .btn-danger, .btn-success {
        i {
            font-size: 16px;
        }
    }
}

.badge {
    border-radius: 5px;
}

.badge-primary {
    background-color: $blue;
}

#page-leftbar {
    width: 230px;
}

#page-content {
    margin-left: 230px;
}

#page-container,
#page-leftbar,
#sidebar ul,
#sidebar ul li a {
    background-color: $white;
}

#page-content,
#page-heading {
    background-color: #f6f6f6;
}

#page-content,
#page-leftbar,
#search form .search-query,
#search form .search-query:focus,
#sidebar .divider,
#sidebar a:hover,
#sidebar ul,
#sidebar ul li a,
#sidebar > li.active > a {
    box-shadow: none;
}

#page-heading .breadcrumb {
    display: none;
}

.nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: $blue;
}

#sidebar .divider,
#sidebar .divider:hover,
#sidebar a:hover {
    background-color: $blue;
}

#sidebar a:hover,
#sidebar a:hover i {
    color: $white;
}

#sidebar li.active a:hover i,
#sidebar li.active i {
    color: $white !important;
}

#sidebar {
    margin-top: 15px;
    z-index: 5;
    i.badge {
        background-color: $blue;
        color: $white;
        top: 5px;
        font-style: normal;
        border-radius: 10px;
        &:hover {
            color: $white !important;
        }
    }
    li {
        a {
            padding: 15px 20px;
            color: $blue;
            font-weight: 700;
            i {
                color: $blue;
            }
        }
    }
    ul li {
        a {
            padding: 5px 10px 5px 45px;
            position: relative;
            &:before {
                content: "\f111";
                font-family: $fa-font;
                font-size: 5px;
                position: absolute;
                left: 27px;
                top: 10px;
            }
        }
    }
    > li.active > a {
        background-color: $blue;
        color: $white;
        font-weight: 600;
        &:after {
            display: none;
        }
    }
    li.active {
        a:hover i:not(.badge),
        i {
            color: $white !important;
        }
        a:hover i.badge,
        i.badge {
            color: $white !important;
            &:hover {
                color: $white !important;
            }
        }
    }
}

#sidebar ul li.active:not(.open) > a {
    font-weight: 400;
    color: $blue;
}

#sidebar ul li.active:not(.open) > a:hover {
    color: $white;
}

#page-heading h1 {
    font-weight: 400;
    font-size: 32px;
    color: $blue;
}

.btn-primary {
    background-color: $blue;
    border-color: $blue;
}

.btn-default {
    background-color: $orange;
    border-color: $orange;
    color: $white;
}

.btn-secondary {
    background-color: $blue;
    border-color: $blue;
    color: $white;
}

.btn-default:active,
.btn-default:focus,
.btn-default:hover {
    background-color: darken($orange, 5);
    color: $white;
}

.btn-primary.active,
.btn-primary:focus,
.btn-primary:hover,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:focus,
.open > .dropdown-toggle.btn-primary {
    background-color: darken($blue, 10);
    color: $white;
}

.center {
    text-align: center;
}

/* custom radios & checkboxes */

.checkbox-custom,
.radio-custom {
    opacity: 0;
    position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
    position: relative;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
    content: '';
    background: $white;
    border: 2px solid #ddd;
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    margin-right: 5px;
    text-align: center;
}

.radio-custom + .radio-custom-label:before {
    width: 20px;
    height: 20px;
}

.checkbox-custom + .checkbox-custom-label:before {
    width: 22px;
    height: 22px;
    line-height: 14px;
    font-size: 10px;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
    content: "\f00c";
    //font-family: 'FontAwesome';
    background: $blue;
    color: $white;
    box-shadow: inset 0px 0px 0px 2px $white;

}

.radio-custom + .radio-custom-label:before {
    border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
    background: $blue;
    box-shadow: inset 0px 0px 0px 3px $white;
}

/**** Pagination */

.pagination {
    margin: 0 !important;
    > li > a,
    > li > span {
        color: $blue;
        border-color: $blue;
    }
    > .active > a,
    > .active > a:focus,
    > .active > a:hover,
    > .active > span,
    > .active > span:focus,
    > .active > span:hover {
        background-color: $blue;
        border-color: $blue;
    }
    > li > a:focus,
    > li > a:hover,
    > li > span:focus,
    > li > span:hover {
        color: $blue;
        background-color: $blue;
    }
}

/**** Panels */

.panel-heading {
    height: auto;
    a {
        display: block;
        color: $greyDark;
    }
}

.panel-group .panel-heading h4 a,
[class*=panel-].panel .panel-heading > h4 {
    color: $blue;
}

.panel.gray .panel-heading h4 > .nav.nav-tabs li a {
    font-size: 14px;
    line-height: 32px;
}

.panel.gray .panel-heading .nav.nav-tabs li.active a {
    color: $blue;
}

.panel {
    border-radius: $panel-border-radius;
    @include shadow;
}

.panel-primary > .panel-heading {
    background-color: $white;
    color: $greyDark;
    border-bottom: 1px $greyLight solid !important;
}

[class*=panel-].panel > .panel-body {
    border: 0;
    padding: 20px;
    position: relative;
    border-radius: $panel-border-radius;
}

[class*=panel-].panel > .panel-heading {
  padding: 5px 20px;
  border-radius: $panel-header-border-radius;
}

#filterForm .btn-primary:not(.active) {
    background-color: $blue;
}

/**** Sidebar repliée */

body.collapse-leftbar #page-leftbar {
    width: 60px;
}

body.collapse-leftbar #page-content,
body.collapse-leftbar footer {
    margin-left: 60px;
}

body.collapse-leftbar #sidebar li li a {
    padding: 5px 10px 5px 45px;
}

body.collapse-leftbar #sidebar > li.active:hover > a,
body.collapse-leftbar #sidebar > li.active:hover > a i {
    color: $blue;
}

.nav-tabs {
    float: left;
    border-bottom: 0;
    > li {
        margin-bottom: -1px;
    }
}

.panel-heading .options .nav-tabs {
    top: 6px;
}

#proposed {
    padding: 20px;
}

// Small devices
@media (max-width: 576px) {
    #page-heading h1 {
        padding: 20px 10px;
        max-width: 75%;
        font-size: 24px;
    }
    header.navbar a.navbar-brand {
        height: 25px;
        padding: 0;
        margin: 17px 0;
        img {
            height: 25px;
        }
    }

    .navbar-header {
        width: auto;
    }

    [class*=panel-].panel .panel-heading {
        padding: 10px 15px;
    }
    [class*=panel-].panel .panel-body {
        padding: 15px;
    }
    #wrap {
        padding: 0;
        > .container {
            padding: 0 10px 10px 10px;
        }
    }
    .navbar-nav .dropdown-toggle {
        background-color: $blue;
        padding: 5px 10px !important;
        &:hover,
        &:focus {
            background-color: $blue;
        }
        &:after {
            content: "\f0d7";
            font-family: $fa-font;
            font-size: 18px;
            color: $white;
        }
    }
    ul.toolbar {
        background-color: $white;
    }
    .toolbar.navbar-nav {
        margin: 0;
        position: absolute;
        right: 15px;
        top: 15px;
    }
    #page-leftbar {
        width: 0;
    }
    #page-content {
        margin-left: 0;
    }
    label {
        margin-bottom: 5px;
    }
    .form-group {
        margin-bottom: 15px;
    }
}

// Medium devices (tablets, 768px and up)
@media (max-width: 1024px) {
    #page-heading h1 {
        white-space: normal;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    #page-heading .breadcrumb {
        display: block;
    }
    #page-heading h1 {
        font-size: 26px;
    }
}

// Used in src/AdminBundle/Resources/views/Stock/Distributor/form.html.twig
// allows to have a bigger popover to display long-links
.btn-popover-lg + .popover {
    max-width: 70vw;
}

//select option.required {
//    font-weight: bolder;
//}

[class*=panel-].panel .panel-heading .options .nav-tabs li,
[class*=panel-].panel .panel-heading .options .nav-tabs li a {
  border: 1px $white solid !important;
  border-bottom: 1px #eee solid !important;

}

[class*=panel-].panel .panel-heading .options .nav-tabs li.active,
[class*=panel-].panel .panel-heading .options .nav-tabs li.active a {
  border-left: 1px #eee solid !important;
  border-top: 1px #eee solid !important;
  border-right: 1px #eee solid !important;
  border-bottom: 1px $white solid !important;
}

[class*=panel-].panel .panel-heading .nav.nav-tabs>li a:hover {
  color: lighten(black, 25);
}

[class*=panel-].panel .panel-heading .nav.nav-tabs>li a {
  color: #000;
}

[data-toggle="buttons"] > label {
    margin: 0 5px;
}
