@import 'variables.scss';

.history {
  margin: 50px 0;
}
.history ul {
  margin: 0;
  list-style: none;
  position: relative;
  padding: 1px 100px;
  color: $blue;
  font-size: 15px;

  &:before {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    border-left: 2px dashed $blue;
  }

  li {
    position: relative;
    margin-left: 30px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 14px;
    border-radius: 6px;
    width: 100%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.08);

    &:not(:first-child) {
      margin-top: 30px;
    }

    > span {
      width: 2px;
      height: 100%;
      background: $blue;
      left: -30px;
      top: 0;
      position: absolute;

      &:before,
      &:after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        border: 2px solid $blue;
        position: absolute;
        background: #86b7e7;
        left: -5px;
        top: 0;
      }

      &:after {
        top: 100%;
      }
    }

    > div {
      margin-left: 10px;
    }
  }
}

.history div .title,
.history div .type {
  font-weight: 600;
  font-size: 14px;
}
.history div .info {
  font-weight: 300;
}
.history div > div {
  margin-top: 5px;
}
.history span.number {
  height: 100%;
}
.history span.number span {
  position: absolute;
  font-size: 12px;
  left: -75px;
  font-weight: bold;
}
.history span.number span:first-child {
  top: 0;
}
.history span.number span:last-child {
  top: 100%;
}
