@import 'timeline.scss';
@import '~dropzone/dist/dropzone.css';

$orange: #da820e;

.help-warning {
  color: $orange !important;
}

.status-container {
  height: 50px;

  select {
    width: 200px;
    float: right;

    //border-radius: 10px;
    //background: $orange;
    //color: white;
    //border: 0;
    //font-weight: bold;
  }
}

.actions-container {
  text-align: center;

  button, a {
    margin: 0 5px 20px 5px;
    font-size: 14px;
  }
}

.panel-automatic-remind {
    .actions-container {
        margin: 25px 0;
    }
    .option {
        > i {
            font-size: 36px;
            margin: 25px 0;
        }
    }
}

// #######################
// ###### DROPZONE #######
// #######################

.my-dropzone {
    font-weight: bold;
    font-size: 14px;
    border: 4px dashed #ddd;
    color: $orange;
    //max-width: 75%;
    //margin: auto;
    margin-bottom: 20px;

    &:hover {
        color: darken($orange, 5);
    }
    .upload {
        font-size: 60px;
    }
}

body[data-route="admin_dashboard"] {
    #quotation-time-response {
        font-size: 26px;

        .circle {
            display: inline-block;
            border: 2px solid;
            border-radius: 100%;
            width: 50px;
            height: 50px;
            line-height: 45px;
            text-align: center;
        }

        .circle-label {
            font-size: 18px;
        }
    }
}

body[data-route="admin_quote_request_edit"] {
    #page-content {
        position: absolute;
    }
    #page-heading {
        ol {
            margin: 25px 0 0;
        }
        h1 {
            .prev-next-container {
                display: flex;
                justify-content: space-between;
                font-size: 0.75em;
                padding: 5px;
                background: #005e92;
                color: white;
                position: absolute;
                top: 0;
                right: 0;
                left: 0;

                a {
                    color:white;
                    font-size: 0.8em;
                }

                i:first-child {
                    margin-right: 10px;
                }

                i:last-child {
                    margin-left: 10px;
                }
            }
        }
    }
}

